<template>
  <div>
    <a-modal
      width="800px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="短信模版详情"
    >
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>

      <a-spin :spinning="spinning">
        <a-row>
          <a-col :span="12">
            <a-descriptions
              title="基本信息"
              :column="1"
              class="simiot-descriptions simiot-descriptions-max-6"
            >
              <a-descriptions-item label="短信模版名称">
                {{ data.name }}
              </a-descriptions-item>

              <a-descriptions-item label="通用短信模版名称">
                {{ data.sms_template_name }}
              </a-descriptions-item>

              <a-descriptions-item label="运营商种类">
                {{ data.carrier_type }}
              </a-descriptions-item>

              <a-descriptions-item label="流量属性">
                {{ data.traffic_property }}
              </a-descriptions-item>

              <a-descriptions-item label="用户业务模式">
                {{ data.user_business_type }}
              </a-descriptions-item>

              <a-descriptions-item label="短信模版周期">
                {{ data.service_cycle }}
              </a-descriptions-item>

              <a-descriptions-item label="短信模版容量">
                {{ data.package_capacity }}
              </a-descriptions-item>

              <a-descriptions-item label="语音">
                {{ data.voice_capacity }}
              </a-descriptions-item>

              <a-descriptions-item label="短信模版描述">
                {{ data.sms_template_description }}
              </a-descriptions-item>

            </a-descriptions>

            <a-descriptions title="零售设置" :column="1" v-if="data.user_business_type_slug === 'custom_month'">
              <a-descriptions-item label="允许零售">
                {{ data.is_allow_retail }}
              </a-descriptions-item>

              <a-descriptions-item label="零售价设置者" v-if="data.is_allow_retail_slug">
                {{ data.retail_operator }}
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions title="自定义功能" :column="1" v-if="isCustomDay">
              <a-descriptions-item label="是否限制订购周期数">
                {{ data.is_limit_subscribe_cycle }}
              </a-descriptions-item>

              <a-descriptions-item label="单卡可订购周期数(个)" v-if="data.is_limit_subscribe_cycle_slug">
                {{ data.limit_subscribe_cycle }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>

          <a-col :span="12">
            <a-descriptions title="价格设置" :column="favorablePriceColumnSize">
              <a-descriptions-item>
                周期数
              </a-descriptions-item>

              <a-descriptions-item>
                底价(元)
              </a-descriptions-item>

              <a-descriptions-item>
                客户价(元)
              </a-descriptions-item>

              <a-descriptions-item v-if="data.is_allow_retail_slug && data.retail_operator_slug === 'platform'">
                零售价(元)
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              :column="favorablePriceColumnSize"
              v-for="favorablePrice in data.agents_sms_template_favorable_prices"
              :key="`favorable_price_${favorablePrice.cycles}`"
            >
              <a-descriptions-item>
                {{ favorablePrice.cycles }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ favorablePrice.floor_price }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ favorablePrice.distributor_price }}
              </a-descriptions-item>

              <a-descriptions-item v-if="data.is_allow_retail_slug && data.retail_operator_slug === 'platform'">
                {{ favorablePrice.retail_price }}
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions
              :column="3"
              v-if="!isCustomDay && !data.is_allow_retail_slug"
            >
              <a-descriptions-item>
                超额计费单位
              </a-descriptions-item>

              <a-descriptions-item>
                底价(元)
              </a-descriptions-item>

              <a-descriptions-item>
                客户价(元)
              </a-descriptions-item>

              <a-descriptions-item>
                {{ data.excess_rate_quantity }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ data.excess_floor_price }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ data.excess_distributor_price }}
              </a-descriptions-item>
            </a-descriptions>

            <a-descriptions title="卡片设置" :column="3">
              <a-descriptions-item>
                卡片材质
              </a-descriptions-item>

              <a-descriptions-item>
                底价(元)
              </a-descriptions-item>

              <a-descriptions-item>
                客户价(元)
              </a-descriptions-item>

            </a-descriptions>

            <a-descriptions
              :column="3"
              v-for="agentsSmsTemplatesCardType in data.agents_sms_templates_card_types"
              :key="`card_type_${agentsSmsTemplatesCardType.id}`"
            >
              <a-descriptions-item>
                {{ agentsSmsTemplatesCardType.card_type_name }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ agentsSmsTemplatesCardType.floor_price | formatFloat }}
              </a-descriptions-item>

              <a-descriptions-item>
                {{ agentsSmsTemplatesCardType.price | formatFloat }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgentsSmsTemplate } from '@/api/agents_sms_template'

export default {
  name: 'ShowAgentsSmsTemplate',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      userBusinessType: '',
      favorablePriceColumnSize: 3,
      isCustomDay: false,
      spinning: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.spinning = true
      findAgentsSmsTemplate(this.id).then((res) => {
        this.data = res.data
        this.userBusinessType = this.data.user_business_type_slug
        this.favorablePriceColumnSize = this.data.is_allow_retail_slug && this.data.retail_operator_slug === 'platform' ? 4 : 3
        if (this.userBusinessType === 'custom_day') {
          this.isCustomDay = true
        }

        this.spinning = false
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>
